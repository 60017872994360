
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapState } from "vuex";
import SalesOrder from "@/types/salesorder";
import Card from "primevue/card";
import ProductionStage from "@/components/Manufacturing/ProductionStage.vue";
import NotificationCard from "@/components/Notification-Card.vue";


export default defineComponent({
  name: "SalesOrders",
  components: {
    ProductionStage,
    NotificationCard,
    Card,

  },
  computed: {
    ...mapState(["shipControl"]),
    ...mapGetters({
    }),
  },
  data() {
    return {
      items: [] as Array<SalesOrder>,
      selectedSalesID: null,
      selectedCustomer: null,
      selectedSale: null,
      selectedRow: null,
      order: false,
      orderRecordsBy: "",
      selectedSalesStatus: null,
      readOnly: true,
      sortOrder: 1,
      sortField: "",
      first: 0,
      rows: 10,
      page: 1,
      isLoadingSales: false,
      status: [
        { status: "New", initial: "N" },
        { status: "Back Order", initial: "B" },
        { status: "Closed", initial: "C" },
      ],
      showSalesDialog: false,
      salesOrder: null as unknown,
      isResultView: true as boolean,
      lastUpdated: Date.now(),
      filename:"SOH.WEB",
      productionStage:["D"],
      pageTitle: "Deleted",
      dynamicColumns: [
      { field: 'so_id', header: 'Sales Order' , input: true},
      { field: 'CUST_CODE', header: 'Cust Acc No', input: true },
      { field: 'PO_NUMBER', header: 'Cust PO' , input: true},
      { field: 'CUST_PART_NO_VIEW', header: 'Cust Part No' , input: true},
      { field: 'FORMULA_PART_VIEW', header: 'Formula Part#', input: true },
      { field: 'COSWAY_PART', header: 'Cosway Part Nbr' , input: true},
      
      { field: 'UNIT_SIZE', header: 'Size', input: true },

      { field: 'SOH_REQ_DATE', header: 'Date Req' , input: false,calendar: true},
      { field: 'DATE_REL', header: 'Date Rel' , input: false,calendar: true},
      { field: 'DELETED_USERID', header: 'Deleted By' , input: true,calendar: false},
      { field: 'DELETED_DATE', header: 'Delete Date' , input: false,calendar: true},
      { field: 'DELETED_TIME', header: 'Delete Time' , input: true,calendar: false},

      

      ]
    };
  },

  methods: {
    ...mapActions({
    
    }),
    openSales(edit: boolean) {
      this.showSalesDialog = true;
      this.readOnly = edit;
    },
    isResultViewChange(event: boolean) {
      this.isResultView = event
    },
    isDataUpdated(event: number) {
      this.lastUpdated = event;
    },      
  },
});
